/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Subtitle, Button, Image } from '@swp/components'
import SiteHeader from '../../components/de/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Svatební dary"}>
        <SiteHeader />

        <Column className="--center pb--80 pt--80" name={"hochzeitsgeschenke"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1" anim={"2"} animS={"5"} style={{"maxWidth":1090}} columns={"1"}>
            
            <ColumnWrapper className="pt--40">
              
              <Title className="title-box title-box--center fs--102 mt--10" content={"Hochzeitsgeschenke"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":600}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textes, die Schriftgröße und den Zeilenabstand ändern und sogar die maximale Breite des Textes in Pixeln einstellen."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"[[SVTsectionnam3]]-2"}>
          
          <ColumnWrap className="column__flex --center el--2 mt--30 flex--top" style={{"maxWidth":900}} columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box" content={"Kaffeemaschine"}>
              </Subtitle>

              <Text className="text-box mt--06" content={"€ 200 — € 1200"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--06 pt--0">
              
              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

              <Button className="btn-box btn-box--hvr3 btn-box--sbtn2 btn-box--cColor2 fs--26 w--500 mt--20" innerClassName="pb--02 pl--0 pr--0" href={"#"} content={"Unsere Auswahl"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--sbtn2 btn-box--cColor2 fs--26 w--500 mt--06" innerClassName="pb--02 pl--0 pr--0" href={"/de/reservieren"} content={"Reservieren"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 mt--30 flex--top" style={{"maxWidth":900}} columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box" content={"IKEA Tisch"}>
              </Subtitle>

              <Text className="text-box mt--06" content={"€ 200 — € 1200"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--06 pt--0">
              
              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

              <Button className="btn-box btn-box--hvr3 btn-box--sbtn2 btn-box--cColor2 fs--26 w--500 mt--20" innerClassName="pb--02 pl--0 pr--0" href={"https://alza.cz"} content={"Unsere Auswahl"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--sbtn2 btn-box--cColor2 fs--26 w--500 mt--06" innerClassName="pb--02 pl--0 pr--0" href={"/de/reservieren"} content={"Reservieren"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 mt--30 flex--top" style={{"maxWidth":900}} columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box" content={"Fernseher PHILLIPS 48"}>
              </Subtitle>

              <Text className="text-box mt--06" content={"€ 200 — € 1200"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--06 pt--0">
              
              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

              <Button className="btn-box btn-box--hvr3 btn-box--sbtn2 btn-box--cColor2 fs--26 w--500 mt--20" innerClassName="pb--02 pl--0 pr--0" href={"#"} content={"Unsere Auswahl"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--sbtn2 btn-box--cColor2 fs--26 w--500 mt--06" innerClassName="pb--02 pl--0 pr--0" href={"/de/reservieren"} content={"Reservieren"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--60 pt--80" name={"1vxxjbff4ua"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1" anim={"2"} animS={"5"} style={{"maxWidth":1150}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/11212/43bf8fe0c7ce487288585d6049e4ff61_s=860x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/11212/43bf8fe0c7ce487288585d6049e4ff61_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/43bf8fe0c7ce487288585d6049e4ff61_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/43bf8fe0c7ce487288585d6049e4ff61_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/43bf8fe0c7ce487288585d6049e4ff61_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/11212/43bf8fe0c7ce487288585d6049e4ff61_s=2000x_.jpg 2000w"} content={null} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--80" name={"partei"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1" anim={"2"} animS={"5"} style={{"maxWidth":1090}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--cColor2" innerClassName="pt--0 pb--02 pl--0 pr--0" href={"/de/teilnahme-bestatigen"} content={"RSVP"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--cColor2 mt--16" innerClassName="pt--0 pb--02 pl--0 pr--0" href={"/de/hochzeitsgeschenke"} content={"Hochzeitsgeschenke"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--cColor2 mt--16" innerClassName="pt--0 pb--02 pl--0 pr--0" href={"/de/fotogallerie"} content={"Fotogalerie"}>
              </Button>

              <Image className="mt--40" alt={""} src={"https://cdn.swbpg.com/o/11212/0cc2932258564e9dadfbd3f8a0b406da.svg"} svg={false} href={"https://facebook.com"} sizes={"100vw"} style={{"maxWidth":32}} srcSet={""} content={null}>
              </Image>

              <Image className="mt--20" alt={""} src={"https://cdn.swbpg.com/o/11212/c052f05d66c6491c98f55819ed14c078.svg"} svg={false} href={"https://instagram.com"} sizes={"100vw"} style={{"maxWidth":32}} srcSet={""} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--40" name={"partei-2"}>
          
          <ColumnWrap className="column__flex --center el--1" anim={null} animS={null} style={{"maxWidth":1090}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--102 mt--10" content={"Wie freuen uns auf Sie!"}>
              </Title>

              <Text className="text-box text-box--center mt--10" content={"<a href=\"https://saywebpage.com\">Webproduktion stránek</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}